exports.components = {
  "component---src-pages-404-en-tsx": () => import("./../../../src/pages/404.en.tsx" /* webpackChunkName: "component---src-pages-404-en-tsx" */),
  "component---src-pages-404-it-tsx": () => import("./../../../src/pages/404.it.tsx" /* webpackChunkName: "component---src-pages-404-it-tsx" */),
  "component---src-pages-company-en-tsx": () => import("./../../../src/pages/company.en.tsx" /* webpackChunkName: "component---src-pages-company-en-tsx" */),
  "component---src-pages-index-de-tsx": () => import("./../../../src/pages/index.de.tsx" /* webpackChunkName: "component---src-pages-index-de-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-fr-tsx": () => import("./../../../src/pages/index.fr.tsx" /* webpackChunkName: "component---src-pages-index-fr-tsx" */),
  "component---src-pages-index-it-tsx": () => import("./../../../src/pages/index.it.tsx" /* webpackChunkName: "component---src-pages-index-it-tsx" */),
  "component---src-pages-prodotti-corsi-di-lingua-corso-di-arabo-it-tsx": () => import("./../../../src/pages/prodotti/corsi-di-lingua/corso-di-arabo.it.tsx" /* webpackChunkName: "component---src-pages-prodotti-corsi-di-lingua-corso-di-arabo-it-tsx" */),
  "component---src-pages-prodotti-corsi-di-lingua-corso-di-francese-it-tsx": () => import("./../../../src/pages/prodotti/corsi-di-lingua/corso-di-francese.it.tsx" /* webpackChunkName: "component---src-pages-prodotti-corsi-di-lingua-corso-di-francese-it-tsx" */),
  "component---src-pages-prodotti-corsi-di-lingua-corso-di-inglese-it-tsx": () => import("./../../../src/pages/prodotti/corsi-di-lingua/corso-di-inglese.it.tsx" /* webpackChunkName: "component---src-pages-prodotti-corsi-di-lingua-corso-di-inglese-it-tsx" */),
  "component---src-pages-prodotti-corsi-di-lingua-corso-di-italiano-it-tsx": () => import("./../../../src/pages/prodotti/corsi-di-lingua/corso-di-italiano.it.tsx" /* webpackChunkName: "component---src-pages-prodotti-corsi-di-lingua-corso-di-italiano-it-tsx" */),
  "component---src-pages-prodotti-corsi-di-lingua-corso-di-spagnolo-it-tsx": () => import("./../../../src/pages/prodotti/corsi-di-lingua/corso-di-spagnolo.it.tsx" /* webpackChunkName: "component---src-pages-prodotti-corsi-di-lingua-corso-di-spagnolo-it-tsx" */),
  "component---src-pages-prodotti-corsi-di-lingua-corso-di-tedesco-it-tsx": () => import("./../../../src/pages/prodotti/corsi-di-lingua/corso-di-tedesco.it.tsx" /* webpackChunkName: "component---src-pages-prodotti-corsi-di-lingua-corso-di-tedesco-it-tsx" */),
  "component---src-pages-prodotti-corsi-di-lingua-it-tsx": () => import("./../../../src/pages/prodotti/corsi-di-lingua.it.tsx" /* webpackChunkName: "component---src-pages-prodotti-corsi-di-lingua-it-tsx" */),
  "component---src-pages-prodotti-corsi-di-lingua-scelte-pedagogiche-it-tsx": () => import("./../../../src/pages/prodotti/corsi-di-lingua/scelte-pedagogiche.it.tsx" /* webpackChunkName: "component---src-pages-prodotti-corsi-di-lingua-scelte-pedagogiche-it-tsx" */),
  "component---src-pages-prodotti-corsi-di-lingua-scelte-tecnologiche-it-tsx": () => import("./../../../src/pages/prodotti/corsi-di-lingua/scelte-tecnologiche.it.tsx" /* webpackChunkName: "component---src-pages-prodotti-corsi-di-lingua-scelte-tecnologiche-it-tsx" */),
  "component---src-pages-prodotti-test-linguistici-it-tsx": () => import("./../../../src/pages/prodotti/test-linguistici.it.tsx" /* webpackChunkName: "component---src-pages-prodotti-test-linguistici-it-tsx" */),
  "component---src-pages-prodotti-test-linguistici-scelte-pedagogiche-it-tsx": () => import("./../../../src/pages/prodotti/test-linguistici/scelte-pedagogiche.it.tsx" /* webpackChunkName: "component---src-pages-prodotti-test-linguistici-scelte-pedagogiche-it-tsx" */),
  "component---src-pages-prodotti-test-linguistici-scelte-tecnologiche-it-tsx": () => import("./../../../src/pages/prodotti/test-linguistici/scelte-tecnologiche.it.tsx" /* webpackChunkName: "component---src-pages-prodotti-test-linguistici-scelte-tecnologiche-it-tsx" */),
  "component---src-pages-products-language-courses-arabic-course-en-tsx": () => import("./../../../src/pages/products/language-courses/arabic-course.en.tsx" /* webpackChunkName: "component---src-pages-products-language-courses-arabic-course-en-tsx" */),
  "component---src-pages-products-language-courses-en-tsx": () => import("./../../../src/pages/products/language-courses.en.tsx" /* webpackChunkName: "component---src-pages-products-language-courses-en-tsx" */),
  "component---src-pages-products-language-courses-english-course-en-tsx": () => import("./../../../src/pages/products/language-courses/english-course.en.tsx" /* webpackChunkName: "component---src-pages-products-language-courses-english-course-en-tsx" */),
  "component---src-pages-products-language-courses-french-course-en-tsx": () => import("./../../../src/pages/products/language-courses/french-course.en.tsx" /* webpackChunkName: "component---src-pages-products-language-courses-french-course-en-tsx" */),
  "component---src-pages-products-language-courses-german-course-en-tsx": () => import("./../../../src/pages/products/language-courses/german-course.en.tsx" /* webpackChunkName: "component---src-pages-products-language-courses-german-course-en-tsx" */),
  "component---src-pages-products-language-courses-italian-course-en-tsx": () => import("./../../../src/pages/products/language-courses/italian-course.en.tsx" /* webpackChunkName: "component---src-pages-products-language-courses-italian-course-en-tsx" */),
  "component---src-pages-products-language-courses-pedagogical-choices-en-tsx": () => import("./../../../src/pages/products/language-courses/pedagogical-choices.en.tsx" /* webpackChunkName: "component---src-pages-products-language-courses-pedagogical-choices-en-tsx" */),
  "component---src-pages-products-language-courses-spanish-course-en-tsx": () => import("./../../../src/pages/products/language-courses/spanish-course.en.tsx" /* webpackChunkName: "component---src-pages-products-language-courses-spanish-course-en-tsx" */),
  "component---src-pages-products-language-courses-technological-choices-en-tsx": () => import("./../../../src/pages/products/language-courses/technological-choices.en.tsx" /* webpackChunkName: "component---src-pages-products-language-courses-technological-choices-en-tsx" */),
  "component---src-pages-products-language-tests-en-tsx": () => import("./../../../src/pages/products/language-tests.en.tsx" /* webpackChunkName: "component---src-pages-products-language-tests-en-tsx" */),
  "component---src-pages-products-language-tests-pedagogical-choices-en-tsx": () => import("./../../../src/pages/products/language-tests/pedagogical-choices.en.tsx" /* webpackChunkName: "component---src-pages-products-language-tests-pedagogical-choices-en-tsx" */),
  "component---src-pages-products-language-tests-technological-choices-en-tsx": () => import("./../../../src/pages/products/language-tests/technological-choices.en.tsx" /* webpackChunkName: "component---src-pages-products-language-tests-technological-choices-en-tsx" */),
  "component---src-pages-produits-cours-de-langues-choix-pedagogiques-fr-tsx": () => import("./../../../src/pages/produits/cours-de-langues/choix-pedagogiques.fr.tsx" /* webpackChunkName: "component---src-pages-produits-cours-de-langues-choix-pedagogiques-fr-tsx" */),
  "component---src-pages-produits-cours-de-langues-choix-technologiques-fr-tsx": () => import("./../../../src/pages/produits/cours-de-langues/choix-technologiques.fr.tsx" /* webpackChunkName: "component---src-pages-produits-cours-de-langues-choix-technologiques-fr-tsx" */),
  "component---src-pages-produits-cours-de-langues-cours-d-allemand-fr-tsx": () => import("./../../../src/pages/produits/cours-de-langues/cours-d-allemand.fr.tsx" /* webpackChunkName: "component---src-pages-produits-cours-de-langues-cours-d-allemand-fr-tsx" */),
  "component---src-pages-produits-cours-de-langues-cours-d-anglais-fr-tsx": () => import("./../../../src/pages/produits/cours-de-langues/cours-d-anglais.fr.tsx" /* webpackChunkName: "component---src-pages-produits-cours-de-langues-cours-d-anglais-fr-tsx" */),
  "component---src-pages-produits-cours-de-langues-cours-d-arabe-fr-tsx": () => import("./../../../src/pages/produits/cours-de-langues/cours-d-arabe.fr.tsx" /* webpackChunkName: "component---src-pages-produits-cours-de-langues-cours-d-arabe-fr-tsx" */),
  "component---src-pages-produits-cours-de-langues-cours-d-espagnol-fr-tsx": () => import("./../../../src/pages/produits/cours-de-langues/cours-d-espagnol.fr.tsx" /* webpackChunkName: "component---src-pages-produits-cours-de-langues-cours-d-espagnol-fr-tsx" */),
  "component---src-pages-produits-cours-de-langues-cours-d-italien-fr-tsx": () => import("./../../../src/pages/produits/cours-de-langues/cours-d-italien.fr.tsx" /* webpackChunkName: "component---src-pages-produits-cours-de-langues-cours-d-italien-fr-tsx" */),
  "component---src-pages-produits-cours-de-langues-cours-de-francais-fr-tsx": () => import("./../../../src/pages/produits/cours-de-langues/cours-de-francais.fr.tsx" /* webpackChunkName: "component---src-pages-produits-cours-de-langues-cours-de-francais-fr-tsx" */),
  "component---src-pages-produits-cours-de-langues-fr-tsx": () => import("./../../../src/pages/produits/cours-de-langues.fr.tsx" /* webpackChunkName: "component---src-pages-produits-cours-de-langues-fr-tsx" */),
  "component---src-pages-produits-tests-de-langues-choix-pedagogiques-fr-tsx": () => import("./../../../src/pages/produits/tests-de-langues/choix-pedagogiques.fr.tsx" /* webpackChunkName: "component---src-pages-produits-tests-de-langues-choix-pedagogiques-fr-tsx" */),
  "component---src-pages-produits-tests-de-langues-choix-technologiques-fr-tsx": () => import("./../../../src/pages/produits/tests-de-langues/choix-technologiques.fr.tsx" /* webpackChunkName: "component---src-pages-produits-tests-de-langues-choix-technologiques-fr-tsx" */),
  "component---src-pages-produits-tests-de-langues-fr-tsx": () => import("./../../../src/pages/produits/tests-de-langues.fr.tsx" /* webpackChunkName: "component---src-pages-produits-tests-de-langues-fr-tsx" */),
  "component---src-pages-societa-it-tsx": () => import("./../../../src/pages/societa.it.tsx" /* webpackChunkName: "component---src-pages-societa-it-tsx" */),
  "component---src-pages-societa-lavora-con-noi-it-tsx": () => import("./../../../src/pages/societa/lavora-con-noi.it.tsx" /* webpackChunkName: "component---src-pages-societa-lavora-con-noi-it-tsx" */),
  "component---src-pages-societe-fr-tsx": () => import("./../../../src/pages/societe.fr.tsx" /* webpackChunkName: "component---src-pages-societe-fr-tsx" */),
  "component---src-pages-societe-rejoignez-nous-fr-tsx": () => import("./../../../src/pages/societe/rejoignez-nous.fr.tsx" /* webpackChunkName: "component---src-pages-societe-rejoignez-nous-fr-tsx" */),
  "component---src-pages-society-work-with-us-en-tsx": () => import("./../../../src/pages/society/work-with-us.en.tsx" /* webpackChunkName: "component---src-pages-society-work-with-us-en-tsx" */),
  "component---src-pages-solutions-en-tsx": () => import("./../../../src/pages/solutions.en.tsx" /* webpackChunkName: "component---src-pages-solutions-en-tsx" */),
  "component---src-pages-solutions-fr-tsx": () => import("./../../../src/pages/solutions.fr.tsx" /* webpackChunkName: "component---src-pages-solutions-fr-tsx" */),
  "component---src-pages-soluzioni-it-tsx": () => import("./../../../src/pages/soluzioni.it.tsx" /* webpackChunkName: "component---src-pages-soluzioni-it-tsx" */)
}

